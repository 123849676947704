
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
               <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description"
              label-for="basicInput"
            >
             <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.description"
                :plaintext="readOnly"
                placeholder="Description"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
            <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
              required
                id="Prix"
                v-model="form.price"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                :state="errors.length > 0 ? false:null"
                placeholder="Prix"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>

    </b-card>

    <b-button v-if="edition==false"
     @click="createProcessing"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="editProcessing"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BForm, BRow, BCol, BOverlay, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest} from '@simb2s/senseye-sdk'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'ProcessingForm',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BOverlay,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  data() {
      return {
          edition:false,
          currentBase64:null,
          loadind:true,
          file: null,
          form:{
            name:"",
            price:0,
            description:''
          },
      }
  },
  props: ['processing', 'readOnly'],
  methods:{
    async createProcessing()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
        
          await APIRequest.create('processings',this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Le traitement a bien été créé !",
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'processings'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    async editProcessing()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        try {
        
          await APIRequest.update('processings',this.processing.id,this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Le traitement a bien été modifié !",
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'processings'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
 
  },

  
    
  async mounted(){
   if(this.processing!=null)
    {
      this.form=this.processing
      this.edition=true
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>