<template>
    <ProcessingForm :processing="processing" v-if="loaded" :readOnly="false" />
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ProcessingForm from './ProcessingForm.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    ProcessingForm
  },
  data() {
    return {
      processing:null,
      loaded:false,
    }
  },
  methods:{
    
  },
  async mounted()
  {
    this.processing=await APIRequest.detail('processings',this.$route.params.id)
    this.loaded=true
  }
}
</script>
